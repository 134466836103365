import {
  JsonObject,
  JsonProperty,
  PropertyConvertingMode,
} from "json2typescript";
import CommonModuleMetadata from "./CommonModuleMetadata";

@JsonObject("DropModuleMetadata")
export default class DropModuleMetadata extends CommonModuleMetadata {
  /**
   * The amount of royalty collected on all royalties represented as basis points.
   * The default is 0 (no royalties).
   *
   * 1 basis point = 0.01%
   *
   * For example: if this value is 100, then the royalty is 1% of the total sales.
   */
  @JsonProperty(
    "seller_fee_basis_points",
    Number,
    PropertyConvertingMode.IGNORE_NULLABLE,
  )
  sellerFeeBasisPoints? = 0;

  /**
   * The amount of fees collected on the primary sale, represented as basis points. The default is 0.
   *
   * For example, if the primary sale is $100 and the primary fee is 1000 BPS, then the primary fee of $10
   * is distributed to the primary sale recipient.
   *
   * The destination for this fee is either the project address, or a Splits contract address
   * if it was set using the `setModuleRoyaltyTreasury` method.
   *
   * 1 basis point = 0.01%
   *
   * For example: if this value is 100, then the royalty is 1% of the total sales.
   */
  @JsonProperty(
    "primary_sale_fee_basis_points",
    Number,
    PropertyConvertingMode.IGNORE_NULLABLE,
  )
  primarySaleFeeBasisPoints? = 0;

  /**
   * The address of the royalty recipient. All royalties will be sent
   * to this address.
   */
  @JsonProperty("fee_recipient", String, PropertyConvertingMode.IGNORE_NULLABLE)
  feeRecipient?: string = undefined;

  /**
   * The symbol for the Drop Collection
   */
  @JsonProperty("symbol", String, PropertyConvertingMode.IGNORE_NULLABLE)
  symbol?: string;

  /**
   * The max supply (required)
   */
  @JsonProperty("max_supply", Number, PropertyConvertingMode.IGNORE_NULLABLE)
  maxSupply = 1;

  /**
   * The address of the receiver of the initial sale.You can use this field to
   * distribute the initial sale proceeds. All drop contracts are required to set this field,
   * even if the tokens are planned to be free. If you plan to do a free drop, you can set this
   * field to `AddressZero`
   *
   * This does not effect royalties in any way.
   *
   * For example, if a token is sold for $100, and there is a 0% fee,
   * then $100 will be distributed to the address set on this property.
   *
   * If the token is sold for $100, and there is a 10% primary sale fee,
   * then $90 will be distributed to the address set on this property.
   *
   * All secondary sales will use the `sellerFeeBasisPoints` and royalty recipient address.
   */
  @JsonProperty("primary_sale_recipient_address", String)
  primarySaleRecipientAddress = "";

  /**
   * The IPFS base URI thats prepended to all token URIs. This allows
   * you to batch upload all the metadata for the tokens ahead of time
   * so they can be lazy minted later
   *
   * e.g. if a Drop Collection has a token URI of /ipfs/BOREDAPES/TOKEN1, then the IPFS base URI is /BOREDAPES/
   */
  @JsonProperty(
    "base_token_uri",
    String,
    PropertyConvertingMode.IGNORE_NULLABLE,
  )
  baseTokenUri? = "";
}
